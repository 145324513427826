
import Button from 'primevue/button';
import { defineComponent, inject, Ref } from 'vue';

export default defineComponent({
  props: {
    theme: String,
  },
  components: {
    Button,
  },
  setup() {
    const proceedToOrder = inject('proceedToOrder') as () => void;
    const isGuideRequested = inject('isGuideRequested') as Ref<boolean>;
    function proceedToOrderWithGuide() {
      proceedToOrder();
      isGuideRequested.value = true;
    }
    return {
      proceedToOrderWithGuide,
    };
  },
});

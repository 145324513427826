
import { defineComponent, inject } from 'vue';
import GuideDownloadButton from '@/components/GuideDownloadButton.vue';
// import Chip from 'primevue/chip';
import Card from 'primevue/card';
import Button from 'primevue/button';

export default defineComponent({
  name: 'Tariffs',
  components: {
    // Chip,
    Card,
    Button,
    GuideDownloadButton,
  },
  setup() {
    const months = inject('months') as string[];
    const month = months[(new Date()).getMonth()];
    const proceedToOrder = inject('proceedToOrder');
    // const showTariff = inject('showTariff');
    const tariffs = inject('tariffs') as Record<string, unknown>[];
    return {
      proceedToOrder,
      // showTariff,
      month,
      tariffs,
    };
  },
});
